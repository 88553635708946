<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

        <tchtree v-model="searchForm.class_id" @change="getlist" :isAll="1"></tchtree>
        <el-input v-model="searchForm.stu_name" size="small" placeholder="姓名" clearable
          style="max-width: 200px; margin-right: 10px;" />
        <!-- <el-input v-model="searchForm.stu_code" size="small" placeholder="学号" clearable
          style="max-width: 200px; margin-right: 10px;" /> -->

        <el-input v-model="searchForm.phone" size="small" placeholder="联系电话" clearable
          style="max-width: 200px; margin-right: 10px;" />
        <el-input v-model="searchForm.firm_name" size="small" placeholder="工作单位/企业" clearable
          style="max-width: 200px; margin-right: 10px;" />

        <!-- <el-select v-model="searchForm.year" @change="changeYear" placeholder="毕业年份" size="small"
          style="width: 120px; margin-left: 10px;">
          <el-option label="全部毕业年份" value=""></el-option>
          <el-option v-for="(y, i) in YearList" :label="y.gyear" :value="y.gyear"></el-option>
        </el-select> -->
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
        <el-button type="primary" plain size="small" icon="el-icon-upload2" @click="importDialog = true">导入毕业生
        </el-button>
        <el-button class="ml10" size="small" icon="el-icon-refresh" @click="tongbu" type="primary"
          plain>同步系统已毕业学生</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>



    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="by_year" label="毕业年份" width="85" align="center"></el-table-column>
      <!-- <el-table-column prop="stu_code" label="学号" width="120" align="center"></el-table-column> -->
      <el-table-column prop="stu_name" label="姓名" width="100" align="center"></el-table-column>
      <el-table-column prop="sex" label="性别" width="60" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sex == '1'">男</span>
          <span v-if="scope.row.sex == '2'">女</span>
          <span v-if="scope.row.sex == '0'">-</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系电话" width="135" align="center"></el-table-column>
      <el-table-column prop="class_name" label="班级" width="120" align="center"></el-table-column>
      <el-table-column prop="mailbox" label="邮箱" min-width="135" />
      <el-table-column prop="firm_name" label="实际工作单位名称" min-width="260"></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template slot-scope="scope">
          <el-tag size="small" @click="onView(scope.row)" style="cursor: pointer;">详情</el-tag>


          <span v-if="scope.row.openid">
            <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red"
              @confirm="unBind(scope.row)" placement="left" title="是否确定解绑？">
              <el-tag class="ml10 pointer" slot="reference" type="warning" size="small">解绑</el-tag>
            </el-popconfirm>
          </span>
          <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info" iconColor="red"
            @confirm="onDel(scope.row)" placement="left" title="是否确定删除？">
            <el-tag class="ml10 pointer" slot="reference" type="danger" size="small">删除</el-tag>

          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog title="详情" :visible.sync="visible" width="800px" custom-class="width_800 cus_dialog">
      <el-form :model="detailForm" label-width="80px">
        <el-row>
          <el-col :span=12>
            <el-form-item label="姓名:" prop="plan_name">{{ detailForm.stu_name }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="性别:" prop="plan_name">
              <span v-if="detailForm.sex == '1'">男</span>
              <span v-if="detailForm.sex == '2'">女</span>
              <span v-if="detailForm.sex == '0'">未知</span>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="手机:" prop="plan_name">{{ detailForm.phone || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="邮箱:" prop="plan_name">{{ detailForm.mailbox || '-' }}</el-form-item>
          </el-col>

          <el-col :span=12 v-if="$store.state.pmid != 209">
            <el-form-item label="教育阶段:" prop="plan_name">{{ detailForm.graduate || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="政治面貌:" prop="plan_name">{{ detailForm.political_status || '-' }}</el-form-item>
          </el-col>

          <el-col :span=12 v-if="$store.state.pmid != 209">
            <el-form-item label="院（系）:" prop="plan_name">{{ detailForm.departments_name || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12 v-if="$store.state.pmid != 209">
            <el-form-item label="年级:" prop="plan_name">{{ detailForm.grade_name || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="班级:" prop="plan_name">{{ detailForm.class_name || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12 v-if="$store.state.pmid != 209">
            <el-form-item label="入学年份:" prop="plan_name">{{ detailForm.start_year || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12 v-if="$store.state.pmid == 209">
            <el-form-item label="毕业年份:" prop="plan_name">{{ detailForm.by_year || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="工作城市:">{{ detailForm.city || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="工作地址:">{{ detailForm.site || '-' }}</el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="所属行业:">{{ detailForm.industry_name || '-' }}</el-form-item>
          </el-col>

          <el-col :span=12>
            <el-form-item label="单位性质:" prop="plan_name">{{ detailForm.unit_nature_name || '-' }}</el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="岗位类型:" prop="plan_name">{{ detailForm.job_type_name || '-' }}</el-form-item>
          </el-col>

          <el-col :span='24'>
            <el-form-item label="个人荣誉:" prop="plan_name">{{ detailForm.achieve || '-' }}</el-form-item>
          </el-col>
          <el-col :span='24'>
            <el-form-item label="兴趣爱好:" prop="plan_name">{{ detailForm.hobby||'-' }}</el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false" type="primary" plain>关 闭</el-button>
      </div>
    </el-dialog>


    <el-dialog title="导入毕业生" :visible.sync="importDialog" width="800px" :close-on-click-modal="false"
      :destroy-on-close="true" custom-class="cus_dialog cus_dialogMin">
      <div style="text-align:center">
        <el-upload style="display: inline-block;" action :multiple="true" :http-request="uploadTemp"
          :on-success="handleXlsUpload" :show-file-list="false" name="image">
          <el-button id="btnimport1" type="primary" plain style="margin-left: 10px; "
            icon="el-icon-upload">选择文件</el-button>
        </el-upload>
        <el-button class="ml10" type="text" icon="el-icon-download">
          <a href="/xls/毕业生导入模板[214].xlsx" style="text-decoration: none;color:inherit">下载毕业生导入模板</a>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../../com/tchTree.vue";
export default {
  components: { tchtree },
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      importDialog: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      arrNation:[],
      arrPolitical: [],
      arrSalary: [],
      arrUnit: [],
      arrIndustry: [],
      arrJobType: [],
    };
  },
  mounted() {
    this.getYears().then(_ => {
      this.getlist();
    })
    this.getDic();
    this.getIndusList()
    this.getPositionList()
  },
  methods: {
    getDic() {
      return new Promise(resolve => {
        this.$http.post("/api/sys_param_list", {
          param_type:
            "'NATION','POLITICAL_STATUS','SKILL_TYPE','SALARY_RANGE','ENTER_INFO'"
        }).then(res => {
          res.data.map(a => {
            if (a.param_type == 'NATION') {
              this.arrNation.push(a)
            }
            if (a.param_type == 'POLITICAL_STATUS') {
              this.arrPolitical.push(a)
            }
            if (a.param_type == 'SALARY_RANGE') {
              this.arrSalary.push(a)
            }
            if (a.param_type == 'ENTER_INFO') {
              this.arrUnit.push(a)
            }
            resolve()
          })
        })
      })
    },

    //获取行业 固定2级
    getIndusList() {
      this.$http.post("/api/zp_industry_tree").then(res => {
        this.arrIndustry = res.data.tree
      })
    },
    //获取职位 固定3级
    getPositionList() {
      this.$http.post("/api/zp_job_type_tree").then(res => {
        this.arrJobType = res.data.tree
      })
    },
    onView(e) {
      let _this = this
      let row = JSON.parse(JSON.stringify(e))
      if (row.work_city && this.isJSON(row.work_city)) {
        let citys = JSON.parse(row.work_city)
        row.city = citys[0].name + "-" +citys[1].name
      }
      //民族
      if (row.nationality) {
        _this.arrNation.map((item, index, arr) => {
          if (item.old_id == row.nationality || item.param_value == row.nationality || item.param_desc == row.nationality) {
            row.nationality_name = item.param_desc
          }
        })
      }
      //政治面貌
      if (row.political_status) {
        _this.arrPolitical.map((item, index, arr) => {
          if (item.old_id == row.political_status || item.param_value == row.political_status || item.param_desc == row.political_status) {
            row.political_name = item.param_desc
          }
        })
      }
      //单位类型
      if (row.unit_nature || row.unit_nature >= 0) {
        _this.arrUnit.map((item, index, arr) => {
          if (item.param_value == row.unit_nature) {
            row.unit_nature_name = item.param_desc
          }
        })
      }
      //薪资
      if (row.salary || row.salary >= 0) {
        _this.arrSalary.map((item, index, arr) => {
          if (item.param_value == row.salary) {
            row.salary_name = item.param_desc
          }
        })
      }
      // 行业
      if (row.industry) {
        _this.arrIndustry && _this.arrIndustry.length > 0 && _this.arrIndustry.map((a, ia) => {
          a.children && a.children.length > 0 && a.children.map((b, ib) => {
            if (b.id == row.industry) {
              row.industry_name = a.industry_name + "-" + b.industry_name
              return
            }
          })
        })
      }

      //岗位类型
      if (row.job_type) {
        let jobA = [], jobA1 = [], jobA2 = [];
        jobA = _this.arrJobType
        outerLoop:for (let a = 0; a < jobA.length; a++) {
          for (let b = 0; b < jobA[a].children.length; b++) {
            for (let c = 0; c < jobA[a].children[b].children.length; c++) {
              if (jobA[a].children[b].children[c].id == row.job_type) {
                row.job_type_name = jobA[a].type_name + "-" + jobA[a].children[b].type_name + "-" + jobA[a].children[b].children[c].type_name
                break outerLoop
              }
            }
          }
        }
      }
      this.detailForm = row
      this.visible = true
    },
    getYears() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/njwx_stu_years").then(res => {
          this.YearList = res.data
          this.searchForm.year = res.data[0].gyear
          resolve()
        }).catch(err => {
          resolve()

        })
      })
    },
    getlist() {
      // console.log(207, this.searchForm)
      this.$http.post("/api/by_students_list_pc", {
        page: this.page.current_page,
        pagesize: this.page.per_page,
        stu_name: this.searchForm.stu_name,
        stu_code: this.searchForm.stu_code,
        phone: this.searchForm.phone,
        class_id: this.searchForm.class_id,
        firm_name: this.searchForm.firm_name,
      })
        .then((res) => {
          this.dataList = res.data.data.data;
          this.page = res.data.data.page || this.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    onDel(row) {
      this.$http.post("/api/by_students_delete", { id: row.id }).then((res) => {
        this.$message.success('删除成功！')
        this.getlist();
      });
    },


    handleCancel() { },
    printIframe() {
      var iframe = document.getElementById("printPage");
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    },

    unBind(row) {
      let params = {
        openid: row.openid,
        user_id: row.id,
      }
      this.$http.post('/api/stu_untie', params).then(res => {
        if (res.data.code == 200) {
          this.$message.success('解绑成功')
          this.getlist()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },

    handleXlsUpload(e) {
      this.$http.post("/api/by_students_import", { url: e.src, filename: e.fileName }).then(res => {
        this.$message.success("导入成功");
        this.getlist();
        this.importDialog = false;
      }).catch(err => {

      });
    },
    tongbu() {
      this.$http.post("/api/by_students_init").then(res => {
        this.$message.success("同步成功");
        this.getlist();
      }).catch(err => {

      });
    }
  },
};
</script>
